import React, { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // TODO: use redux-perist instead of localstorage to manage user data and make it globally accessible
  const user = localStorage.getItem("user");

  const [userData, setUserData] = useState(user ? {...JSON.parse(user)} : {isLoggedin: false});

  const updateUserData = (data) => {
    setUserData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const logout = () => {
    setUserData({});
    localStorage.removeItem('user');
    localStorage.removeItem("token");

  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(userData));
  }, [userData]);

  return (
    <UserContext.Provider value={{ userData, updateUserData, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
