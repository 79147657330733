import React, { useRef, useState } from "react";
import "./Login.css";
import InputField from "./components/InputField";
import axios from "../../api/axios";
import { Outlet, useNavigate } from "react-router-dom";
import { OTPForm } from "../common/OTPForm";
import { useUser } from "./UserContext";
import ErrorAlert from "../common/ErrorAlert";
import { useForm } from "react-hook-form";
import { Button } from "../common/Button";
import usePageTitle from "../../hooks/usePageTitle";

export const ForgotPassword = () => {
  usePageTitle("Forgot Password")
  const navigate = useNavigate();
  const { updateUserData } = useUser();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues: { email: "" } });

  const handleForgotPassword = async () => {
    setIsLoading(true);
    try {
      await axios.post("/forgot-password", { email });
      updateUserData({email})
      navigate("/auth/forgot-password/otp");
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleForgotPassword)}
      className="flex flex-col justify-center items-center px-6 h-full"
    >
      <p className="text-center text-[#8c8c8c] text-[20px] font-light mb-3">
        Please enter the email address you used while registering <br /> to
        receive the verification code
      </p>
      <div className="px-10 flex flex-col flex-1 w-full h-full">
        <InputField
          orientation="column"
          label="Email Address"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          register={register}
          disabled={isLoading}
        />
      </div>
      <Button
        width="w-1/2"
        variant={`${
          isLoading ? "secondary" : isDirty ? "primary" : "secondary"
        }`}
        className={`mt-6`}
        disabled={!isDirty || isLoading}
        submitText="Reset Password"
        isLoading={isLoading}
      />
    </form>
  );
};

export const ResetPasswordOTP = () => {
  usePageTitle("Reset Password")
  const { userData } = useUser();
  const navigate = useNavigate();

  let inputRefs = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState({
    field_1: "",
    field_2: "",
    field_3: "",
    field_4: "",
  });

  const { reset } = useForm({
    defaultValues: { ...input },
  });

  const [errorMessage, setErrorMessage] = useState("");

  const clearInputs = () => {
    reset({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
    setInput({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
  };

  const verifyOTP = async () => {
    setIsLoading(true);
    try {
      await axios.post("/verify-otp", {
        email: userData.email,
        otp: input.field_1 + input.field_2 + input.field_3 + input.field_4,
      });
      navigate("/auth/forgot-password/reset");
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      clearInputs();
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <OTPForm
      onSubmit={verifyOTP}
      isLoading={isLoading}
      input={input}
      inputRefs={inputRefs}
      setInput={setInput}
      btnText="Reset Password"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  );
};

export const ResetPassword = () => {
  usePageTitle("Reset Password")
  const navigate = useNavigate();
  const [values, setValues] = useState({ password: "", confirmPassword: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userData, updateUserData } = useUser();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: { ...values },
  });
  const onChange = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const resetPassword = async () => {
    setErrorMessage("");
    setIsLoading(true);
    if (values.password.length < 8) {
      setErrorMessage("Password is not atleast 8 characters");
      setIsLoading(false);
      return;
    }

    if (values.password !== values.confirmPassword) {
      setErrorMessage("Passwords don't match");
      setIsLoading(false);
      return;
    }

    try {
      await axios.post("/update-password", {
        email: userData.email,
        newPassword: values.confirmPassword,
      });
      setErrorMessage("");
      updateUserData({message: "Password change successful."})
      navigate("/auth");
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(resetPassword)}
      className="flex flex-col justify-center items-center px-6 h-full w-[80%]"
    >
      {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
      <div className="w-full h-full flex-1 flex flex-col">
        <InputField
          label="Password"
          name="password"
          type="password"
          value={values.password}
          onChange={onChange}
          register={register}
          disabled={isLoading}
        />
        <InputField
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={values.confirmPassword}
          onChange={onChange}
          register={register}
          disabled={isLoading}
        />
      </div>

      <Button
        width="w-1/2"
        variant={`${
          isLoading ? "secondary" : isDirty ? "primary" : "secondary"
        }`}
        className={`mt-6`}
        disabled={!isDirty || isLoading}
        submitText="Reset Password"
        isLoading={isLoading}
      />
    </form>
  );
};

const ForgotPasswordWrapper = () => {
  return (
    <>
      <nav className="navbar border-t border-t-brand-dark-blue text-brand-dark-blue w-full">
        <div className="flex justify-between items-center w-full">
          <button
            className={`nav-button w-full ease-out transition-all text-2xl border-b border-b-brand-dark-blue font-bold py-4 mb-6`}
          >
            Password Reset
          </button>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default ForgotPasswordWrapper;
