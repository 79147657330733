import "./Device.css";
import React from "react";
import { useState } from "react";
import plate from "../resources/device-plate.png";
import { edit } from "react-icons-kit/fa/edit";
import { Icon } from "react-icons-kit";
import { Button } from "../common/Button";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import TestDetailsForm from "./TestDetailsForm";
import { useUser } from "../Login/UserContext";
import Toast from "./Toast";
import { formatDateMMDDYYYY, formatDateMMDDYYYYHHMMA } from "../../utils/date";
import { useForm } from "react-hook-form";

export function IncorrectStage1() {
  return (
    <div>
      <div className="py-6">
        <p className="devicebody">
          Your machine is no longer paired to your device. *Add bluetooth
          pairing instructions and tips*. Once you connect again, your progress
          should be saved.
        </p>
      </div>
      <div className="style1">
        <button className="devicebtn">Pair Device</button>
      </div>
    </div>
  );
}

export function Stage2({ tempValue }) {
  return (
    // <div className="flex flex-col items-center justify-evenly flex-grow">
    <div className="flex flex-col items-center justify-between flex-grow p-7">
      <div className="pt-6">
        <p className="devicebody !text-[21px] pb-7">
          The ideal temperature for processing results is 60-65 degrees Celsius.
          The device will automatically begin heating up after being powered on.
        </p>
      </div>

      <div className="flex flex-row width2 items-center pb-7">
        <p className="temptext w-full">
          Current Temperature:{" "}
          <span
            className={`temptext w-full text-right ${tempValue > 65 ? "red" : "#1a1a1a"
              }`}
          >
            {tempValue} C
          </span>
        </p>
      </div>
      <p className="font-normal text-[#1a1a1a] text-xl ">
        You can move to the next step while the device heats up.
      </p>
    </div>
  );
}


export function IncorrectStage2({ tempValue }) {
  return (
    <div>
      <div className="py-6">
        <p className="devicebody">
          Your device temperature has risen above the ideal range. Not to worry
          - this should not interfere with the results. Please allow the device
          to cool down, and it will automatically resume processing your test
          results again.
        </p>
      </div>
      <div className="flex flex-row width2 items-center pt-96">
        <p className="temptext">Current Temperature: </p>
        <p className={`temptext ml-64 ${tempValue > 65 ? "red" : "gray"}`}>
          {tempValue}
        </p>
      </div>
    </div>
  );
}

export function Stage4() {
  return (
    <div className="flex flex-col h-full items-center justify-center">
      <div className="flex flex-grow justify-center items-center">
        <p className="temptext">Please insert cassette into the device</p>
      </div>
    </div>
  );
}

export function Stage3({ updateStages, currentStep }) {
  const { updateUserData } = useUser();
  const date = new Date();

  const offset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - offset);
  const formattedDate = date.toISOString().slice(0, 16);

  const [isLoading, setIsLoading] = useState(false);
  const [testDetails, setTestDetails] = useState({
    fullName: "",
    dateOfBirth: "",
    phoneNumber: "",
    healthPlanBeneficiaryNumber: "",
    address: "",
    requisitionNumber: "",
    referringInstitution: "",
    nameOfOrderingClinician: "",
    medicalRecordNumber: "",
    providerEmail: "",
    requestingHealthCareProvider: "",
    timeOfSampleCollection: "",
    healthCardNumber: "",
    timeReceived: formattedDate,
    dateAdministered: "",
    typeOfTest: "",
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({ defaultValues: { ...testDetails } });

  const onChange = (event) => {
    setTestDetails({
      ...testDetails,
      [event.target.name]: event.target.value,
    });
  };

  const saveTest = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/test", testDetails);
      updateUserData({currentTest: response.data.test})
      updateStages(currentStep + 1);
    } catch (error) {
      console.log("save test", error);
      alert(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(saveTest)} className="w-full flex flex-col ">
      <div className="w-full flex flex-row items-start justify-between text-lg px-10 pt-6 pb-2">
        <p className="text-[#1a1a1a]">
          Please fill in the following fields for your patient.
        </p>
        <p className="text-brand-dark-blue cursor-pointer underline">
          Link to OneGen account
        </p>
      </div>

      <div className="mx-12 flex flex-col space-y-4 border border-black py-4 !px-24 overflow-y-scroll max-h-[512px]">
        <TestDetailsForm
          testDetails={testDetails}
          onChange={onChange}
          register={register}
          isLoading={isLoading}
        />
      </div>
      <div className="flex justify-center w-full">
        <Button
          variant={`${
            isLoading ? "secondary" : isDirty ? "primary" : "secondary"
          }`}
          className={`mt-6`}
          width="w-72"
          disabled={!isDirty || isLoading}
          submitText="Next"
          isLoading={isLoading}
        />
      </div>
    </form>
  );
}

export function Stage5({ eta }) {
  const [currentChamber, setCurrentChamber] = useState("A1");
  const [currentGenotype, setCurrentGenotype] = useState("___");
  const [currentStatus, setCurrentStatus] = useState("Identified");
  return (
    <div>
      <div className="py-6">
        <p className="text-[#1a1a1a] font-normal text-left text-2xl w-full">
          The image below represents the cassette inside the device. Each
          chamber corresponds to a different genotype being tested. Hover over
          any of the chambers to see which genotype it is testing for and
          whether that genotype has been found yet. This will update as your
          results process - the chamber for any genotype found will be colored!
        </p>
        <div className="flex flex-row items-center w-full py-10 px-12">
          <img src={plate} className="w-full" alt="device plate" />
        </div>
        <div className="flex flex-row width2 items-center">
          <p className="temptext w-full">
            Estimated Time Left for Processing:{" "}
            <span className={`temptext w-full text-right`}>{eta} minutes</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export function Stage6({ updateStages, currentStep }) {
  const navigate = useNavigate();

  const { userData, updateUserData } = useUser();
  const { currentTest } = userData;

  const [isEditing, setIsEditing] = useState(false);
  const [testDetails, setTestDetails] = useState(currentTest);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({ defaultValues: { ...currentTest } });

  const onChange = (event) => {
    setTestDetails((fields) => ({
      ...fields,
      [event.target.name]: event.target.value,
    }));
  };

  const toggleEditing = () => {
    if(isLoading) return;
    setIsEditing((prev) => !prev);
  };

  const editTestResults = async () => {
    if (isEditing) {
      setIsLoading(true);
      setIsSuccessful(false);
      try {
        const response = await axios.patch(
          `/test/${currentTest.testId}`,
          testDetails
        );
        reset({...response.data.test})
        updateUserData({currentTest: response.data.test})
        setIsEditing(false);
        setIsSuccessful(true);
      } catch (error) {
        console.log("save test", error);
        alert(error.response.data.error);
      } finally {
        setIsLoading(false)
      }
    } else {
      navigate(`/results/detail/${currentTest.testId}`);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(editTestResults)}
      className="w-full flex flex-col h-full"
    >
      <div className="w-full flex flex-row items-start justify-between text-2xl font-semibold px-10 pt-6 pb-2">
        <p className="text-[#1a1a1a]">Test Details:</p>
        <div className="text-brand-dark-blue cursor-pointer">
          <Icon onClick={toggleEditing} size={30} icon={edit} />
        </div>
        {isSuccessful && <Toast />}
      </div>
      <div
        className={`${
          isEditing ? "!px-24" : ""
        } mx-12 flex flex-col flex-grow space-y-4 border border-black py-4 px-9 overflow-y-scroll max-h-[512px]`}
      >
        {!isEditing ? (
          <div className="grid grid-cols-2 grid-rows-5 gap-4 text-left text-[#1a1a1a]">
            <div>Full Name:</div>
            <div className="font-bold">{currentTest.fullName}</div>
            <div>Date of Birth:</div>
            <div className="font-bold">
              {formatDateMMDDYYYY(currentTest.dateOfBirth)}
            </div>
            <div>Phone Number:</div>
            <div className="font-bold">{currentTest.phoneNumber}</div>
            <div>Health Plan Beneficiary Number:</div>
            <div className="font-bold">
              {currentTest.healthPlanBeneficiaryNumber}
            </div>
            <div>Address:</div>
            <div className="font-bold">{currentTest.address}</div>
            <div>Requisition Number:</div>
            <div className="font-bold">{currentTest.requisitionNumber}</div>
            <div>Referring Instituition:</div>
            <div className="font-bold">{currentTest.referringInstitution}</div>
            <div>Name of Ordering Clinician:</div>
            <div className="font-bold">
              {currentTest.nameOfOrderingClinician}
            </div>
            <div>Medical Record Number:</div>
            <div className="font-bold">{currentTest.medicalRecordNumber}</div>
            <div>Provider Email:</div>
            <div className="font-bold">{currentTest.providerEmail}</div>
            <div>Requesting Healthcare Provider:</div>
            <div className="font-bold">
              {currentTest.requestingHealthCareProvider}
            </div>
            <div>Sample Collected:</div>
            <div className="font-bold">
              {formatDateMMDDYYYYHHMMA(currentTest.timeOfSampleCollection)}
            </div>
            <div>Health Card Number:</div>
            <div className="font-bold">{currentTest.healthCardNumber}</div>
            <div>Test Initiated:</div>
            <div className="font-bold">
              {formatDateMMDDYYYYHHMMA(currentTest.timeReceived)}
            </div>
            {/* <div>Date Administered:</div>
            <div className="font-bold">{currentTest.dateAdministered}</div> */}
            <div>Type of Test:</div>
            <div className="font-bold">{currentTest.typeOfTest}</div>
          </div>
        ) : (
          <TestDetailsForm
            isEditing={isEditing}
            testDetails={testDetails}
            onChange={onChange}
            register={register}
            isLoading={isLoading}
          />
        )}
      </div>
      <div className="flex justify-center w-full">

        <Button
          variant={`${
            isLoading
              ? "secondary"
              : isEditing
              ? isDirty
                ? "primary"
                : "secondary"
              : "primary"
          }`}
          className={`mt-6`}
          width="w-72"
          disabled={isEditing && (!isDirty || isLoading)}
          submitText={isEditing ? "Save" : "View Results"}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
}
