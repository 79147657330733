import React from "react";
import axios from "axios";
import {Link, NavLink} from "react-router-dom";
import {useState} from 'react';
import logo from '../resources/ProbeIT.png'
import Navbar from '../Navbar/Navbar.jsx'
import confetti from '../resources/confetti.png'
import googledrive from '../resources/googledrive.png'
import onedrive from '../resources/onedrive.png'
import dropbox from '../resources/dropbox.png'
import './Results.css';
import PDFFile from './PDFFile'

export function Share()
    {
        // const handleGoogleDriveUpload = async () => {
        //     try{
        //         const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload/googleDrive`, PDFFile);
        //         console.log('Successfully uploaded to Google Drive: ', response);
        //     } catch(error) {
        //         console.log('Error while uploading to Google Drive:', error);
        //     }
        // };
        return(<div>
        <div className="flex flex-col items-center pt-8">
                    <h1 className="histtitle py-8 w-2/3 text-left">Enter E-mail address to receive test result PDF: </h1>
                    <div className="flex flex-row w-5/12 justify-around items-center">
                        <input type="email" className='emailinput'></input>
                        <button className="resultbtn3"><Link to="/results/provider">Send</Link></button>
                    </div>
                </div>

                <div className="flex flex-col items-center pt-24">
                    <h1 className="histtitle py-8 w-2/3 text-left">Save Result to Cloud Platform: </h1>
                    <div className="flex flex-row w-2/5 justify-around">
                        <img src={googledrive} className="w-36 h-36 cursor-pointer"/>
                        <img src={onedrive} className="w-36 h-36 cursor-pointer"/>
                        <img src={dropbox} className="w-36 h-36 cursor-pointer"/>

                    </div>
                </div>

                <p className="linkaccount cursor-pointer underline w-full moveright pt-8 pb-24">or Link to OneGen account</p>

                <div className="flex flex-row midwidth justify-around pb-8">
                    {/*<button className="resultbtn2" onClick = {() => {setResultsSent(true)}}>Send Result</button>*/}
                    <button className="resultbtn">Download PDF</button>
                    <Link to="/results/history"><button className="resultbtn">View Previous Results</button></Link>
                </div>
            </div>);
    }

export const Cloud = () => {
    const [resultsSent, setResultsSent] = useState(false);

    
    return (
        <div className="w-full min-h-screen flex flex-col">
            <div className="w-full flex flex-row">
                <div className="w-1/6">
                    <img src={logo} className="w-full object-cover"/>
                </div>
                <div className="w-5/6">
                     <div className="sndhalf py-8 ">
                        <Navbar/>
                    </div>
                </div>
            </div>

            <Share/>



            {/*resultsSent == false ? <div>
                <div className="flex flex-col items-center pt-8">
                    <h1 className="savetext py-8 w-2/3 text-left">Enter E-mail address to receive test result PDF: </h1>
                    <div className="flex flex-row w-2/5 justify-around items-center">
                        <input type="email" className='emailinput'></input>
                        <button className="resultbtn3"><Link to="/results/provider">Send</Link></button>
                    </div>
                </div>

                <div className="flex flex-col items-center pt-24">
                    <h1 className="savetext py-8 w-2/3 text-left">Save Result to Cloud Platform: </h1>
                    <div className="flex flex-row w-2/5 justify-around">
                        <img src={googledrive} className="w-36 h-36 cursor-pointer"/>
                        <img src={onedrive} className="w-36 h-36 cursor-pointer"/>
                        <img src={dropbox} className="w-36 h-36 cursor-pointer"/>

                    </div>
                </div>

                <p className="linkaccount cursor-pointer underline w-full moveright pt-8 pb-24">or Link to OneGen account</p>

                <div className="flex flex-row midwidth justify-around pb-8">
                    {/*<button className="resultbtn2" onClick = {() => {setResultsSent(true)}}>Send Result</button>*
                    <button className="resultbtn">View PDF</button>
                    <button className="resultbtn">Download PDF</button>
                    <button className="resultbtn"><Link to="/results/history">View Previous Results</Link></button>
                </div>
            </div> : <div>
                <div className="flex flex-col items-center">
                    <h1 className="savetext pt-8 pb-2">Results have been saved to the cloud! </h1>
                    <p className="savecaption">View previous test results or send this one to a provider.</p>
                    <img src={confetti} className="py-12"/>
                </div>

                <div className="flex flex-row midwidth justify-around pt-2 pb-8">
                    <button className="resultbtn2"><Link to="/results/history">View Previous Results</Link></button>
                    
                </div>
    </div>*/}
        </div>

    );
}

export default Cloud