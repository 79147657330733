import { x } from "react-icons-kit/feather/x";
import Icon from "react-icons-kit";
import { useEffect, useState } from "react";
import infoIcon from "../resources/info.png";

const Toast = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 5000);
    return () => setTimeout(timer);
  }, []);
  
  return (
    <div
      className={`${
        show ? "flex" : "hidden"
      } shadow-2xl flex justify-center space-x-2 items-center bg-white text-center p-4 pt-4 border !mb-4 border-brand-dark-blue rounded-lg absolute right-0`}
    >
      <img src={infoIcon} alt="info icon" className="w-6 h-6" />
      <p> Remember to power Off your device</p>
      <button onClick={() => setShow(false)} className="!ml-12">
        <Icon icon={x} size={25} />
      </button>
    </div>
  );
};

export default Toast;