import React from "react";
import "./Login.css";
import logo from "../resources/ProbeIT-blue.png";
import { Outlet } from "react-router-dom";

const LoginRegister = () => {
  return (
    <div className="w-full h-full h-screen flex flex-col md:flex-row">
      {/* Left Section */}
      <div className="bg-[#E9F6FD] w-full md:w-[45%] flex items-center justify-center p-4">
        <img
          src={logo}
          className="w-auto max-w-full max-h-full object-contain"
          alt="Logo"
        />
      </div>

      {/* Right Section */}
      {/* <div className="flex-1 flex flex-col items-center p-4 sm:p-6 md:p-10 overflow-y-auto">
        <div className="welcome-section text-center md:text-left">
          <h1 className="text-center text-2xl sm:text-4xl md:text-5xl lg:text-6xl text-brand-dark-blue mb-4 font-semibold">
            Welcome!
          </h1>
          <br></br>
          {/* <br></br> */}
          {/* <h2 className="text-center text-xl sm:text-2xl  mb-4 font-normal">
            Smart Point-of-Care Pharmacogenetic Test Device
          </h2>

          <p className="text-center text-[#8C8C8C] text-base sm:text-lg mb-4 font-normal">
            Be involved in your health with a personalized genetic profile and
            treatment plan in as little as 20 minutes
          </p>
        </div>  */}
      <div className="relative h-full overflow-y-auto flex-1 flex flex-col justify-between items-center p-20 pt-10">
        <div className="welcome-section">
          <h1 className="text-[56px] text-brand-dark-blue mb-6 font-semibold">
            Welcome!
          </h1>
          <h2 className="text-2xl text-[#1a1a1a] mb-6 font-normal">
            Smart Point-of-Care Pharmacogenetic Test Device
          </h2>
          <p className="text-[#8C8C8C] text-xl mb-6 font-normal">
            Be involved in your health with a personalized genetic profile and
            treatment plan in as little as 20 minutes
          </p>
        </div>

        <Outlet />
      </div>
    </div>
  );
};

export default LoginRegister;
