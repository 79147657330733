import "./App.css";
import LoginRegister from "./Components/Login/LoginRegister.jsx";
import LoginRegisterWrapper from "./Components/Login/LoginRegisterWrapper.jsx";
import Results from "./Components/Results/Results.jsx";
import History from "./Components/Results/History.jsx";
import Cloud from "./Components/Results/Cloud.jsx";
import Home from "./Home.jsx";
import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import "@fontsource/roboto/100.css"; // Specify weight
import PDFFile from "./Components/Results/PDFFile.js";
import { UserProvider } from "./Components/Login/UserContext.js"; // Import the UserProvider from your context file
import { WebSocketCall } from "./Components/socket/WebSocketCall.jsx";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import Navbar from "./Components/Navbar/Navbar.jsx";
import ResultDetails from "./Components/Results/ResultDetails.jsx";
import VerifyEmail from "./Components/Login/VerifyEmail.jsx";
import ForgotPasswordWrapper, {
  ForgotPassword,
  ResetPassword,
  ResetPasswordOTP,
} from "./Components/Login/ForgotPasswordWrapper.jsx";
import TwoFactorAuth from "./Components/Login/TwoFactorAuth.jsx";

function App() {
  console.log("Hello from App.js");
  const [socketInstance, setSocketInstance] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonStatus, setButtonStatus] = useState(false);
  const handleClick = () => {
    setButtonStatus((prev) => !prev);
  };


  // console.log("Hello from App.js");
  // debugger;
  useEffect(() => {
    // console.log("Hello from useEffect" );
    if (buttonStatus === true) {
      
        // io.disable('browser client cache');


      const socket = io("ws://localhost:5001/", {
        transports: ["websocket"]
        // cors: {
        //   origin: "*",
        //   methods :["GET","POST"]//localhost:3000",
        //   /*origin: "http://localhost:3000/",*/
        // },
      });


      
      setSocketInstance(
        socket
      ); /*set socket instance to the local state using setSocketInstance to be able to use it throughout the app. */

      socket.on("connect", (data) => {
        console.log("Connected Data",data.id);
      });

      setLoading(false);
      socket.on('connect_error', (err) => {
        // console.log('Connection error:', error);
        console.log("message",err.message);

        // some additional description, for example the status code of the initial HTTP response
        console.log("description ",err.description);

        // some additional context, for example the XMLHttpRequest object
        console.log("context",err.context);
      });

      socket.on("disconnect", (data) => {
        console.log(data);
      });
      

      return function cleanup() {
        socket.disconnect();
      };
    }
  }, [buttonStatus]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthorizedRoute />,
      children: [
        {
          path: "/",
          element: (
            <Home
              setDeviceStarted={setButtonStatus}
              deviceStarted={buttonStatus}
            />
          ),
        },
        {
          path: "/results",
          element: <Results />,
          children: [],
        },
        {
          path: "/results/detail/:testId",
          element: <ResultDetails deviceStarted={buttonStatus} />,
        },
      ],
    },
    {
      path: "/auth",
      element: <LoginRegisterWrapper />,
      children: [
        {
          path: "/auth",
          element: <LoginRegister />,
        },
        {
          path: "/auth/verify-email",
          element: <VerifyEmail />,
        },
        {
          path: "/auth/two-factor-auth",
          element: <TwoFactorAuth />,
        },
        {
          path: "/auth/forgot-password",
          element: <ForgotPasswordWrapper />,
          children: [
            {
              path: "/auth/forgot-password",
              element: <ForgotPassword />,
            },
            {
              path: "/auth/forgot-password/otp",
              element: <ResetPasswordOTP />,
            },
            {
              path: "/auth/forgot-password/reset",
              element: <ResetPassword />,
            },
          ],
        },
      ],
      errorElement: <div>404 NOT FOUND</div>,
    },
    // TODO: old routes. keeping them here incase we need thwm for reference later. we should delete them after features to send result pdf to provider and cloud platforms are implemented.
    {
      path: "/results/cloud",
      element: <Cloud />,
    },
    {
      path: "/results/history",
      element: <History />,
    },
    {
      path: "/results/provider",
      element: <PDFFile />,
    },
  ]);

  function AuthorizedRoute() {
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) return <Navigate to="/auth" />; // if user is not logged in, redirect them to /auth route

    return (
      <div className="flex flex-col h-screen">
        <Navbar deviceStarted={buttonStatus} handleDevice={handleClick} />
        <div
          style={{ scrollbarGutter: "stable" }}
          className="flex-1 overflow-y-scroll w-full"
        >
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      {/* Wraping  Router with the UserProvider */}
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </div>
  );
}

export default App;
