import React, { useState } from "react";
import "./Components/Login/Login.css";
import { Button } from "./Components/common/Button.jsx";
import Device from "./Components/Device/Device.jsx";
import usePageTitle from "./hooks/usePageTitle.js";

const Home = ({ setDeviceStarted, deviceStarted }) => {
usePageTitle("Test")
  const [runTest, setRunTest] = useState(false);
  return (
    <>
      {!runTest ? (
        <div className="w-full min-h-screen flex flex-col">
          <div className="w-full flex-grow flex flex-col justify-center items-center space-y-10 p-6">
            <h1 className="text-brand-dark-blue text-6xl font-semibold">
              Welcome to ProbeIT!
            </h1>
            <p className="text-[#1A1A1A] text-xl text-left">
              Be involved in your health with a personalized genetic <br />{" "}
              profile and treatment plan in as little as 20 minutes
            </p>
            <Button
              type="button"
              submitText={deviceStarted ? "Run Test" : "Start Device"}
              onClick={
                deviceStarted
                  ? () => setRunTest(true)
                  : () => setDeviceStarted(true)
              }
              width="w-72"
            />
          </div>
        </div>
      ) : (
        <Device />
      )}
    </>
  );
};

export default Home;
