import { useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff, eye } from "react-icons-kit/feather";

const InputField = ({
  label,
  value,
  onChange,
  type = "text",
  name,
  placeholder = "",
  orientation = "column",
  disabled = false,
  register
}) => {
  const [isPassword] = useState(type === "password");
  const [show, setShow] = useState(!isPassword);

  const handleToggle = () => {
    setShow((prev) => !prev);
  };

  const getInputType = () => {
    if(isPassword) {
      return show ? "text" : type || "text";
    }
    return type;
  };

  return (
    <div
      className={`${
        orientation === "column"
          ? "flex-col"
          : "flex-row items-center space-x-2 w-full"
      } flex mb-2`}
    >
      <label className="mb-1 font-semibold text-left whitespace-nowrap">
        {label}
      </label>
      <div className="relative w-full">
        <input
          {...register(name, {
              onChange: onChange
              })}
          type={getInputType()}
          className="p-4 rounded bg-[#F8F9FA] w-full"
          value={value}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
        />
        {isPassword && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
            <button
              type="button"
              className="p-2 focus:outline-none"
              onClick={handleToggle}
            >
              <Icon icon={show ? eyeOff : eye} />
            </button>
          </span>
        )}
      </div>
    </div>
  );
};

export default InputField;
