import React, { useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext.js";
import { Button } from "../common/Button.jsx";
import InputField from "./components/InputField.jsx";
import infoIcon from "../resources/info-red.png";
import axios from "../../api/axios.js";
import { useForm } from "react-hook-form";
import Toast from "./components/Toast.jsx";
import validator from "validator";
import usePageTitle from "../../hooks/usePageTitle.js";

const Login = () => {
  usePageTitle("Login - Register");
  const { updateUserData, userData } = useUser();

  const [values, setValues] = useState({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
    deviceId: "",
  });
  const navigate = useNavigate();

  const [selectedForm, setSelectedForm] = useState("login");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const errorMessages = {
    username: "Please enter your username",
    password: "Please enter your password",
    email: "Please enter your email",
    confirmPassword: "Please confirm your password",
    passwordMismatch: "Passwords don't match",
    deviceId: "Please enter your device ID",
  };

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({ defaultValues: { ...values } });

  const selectForm = (formType) => {
    setErrorMessage("");
    setIsLoading(false);
    clearInputs();
    setSelectedForm(formType);
  };

  const clearInputs = () => {
    reset({
      username: "",
      password: "",
      email: "",
      confirmPassword: "",
      deviceId: "",
    });
  };

  const onChange = (e) => {
    setValues((fields) => ({
      ...fields,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = async () => {
    setErrorMessage("");
    setIsLoading(true);
    for (let field of ["username", "password"]) {
      if (!values[field]?.trim()) {
        setErrorMessage(errorMessages[field]);
        setIsLoading(false);
        return;
      }
    }
    try {
      const response = await axios.post(`/login`, {
        username: values.username,
        password: values.password,
      });
      if (response.data.email) {
        updateUserData({ email: response.data.email });
        if (response.data.message === "email not verified") {
          navigate("/auth/verify-email");
        } else navigate("/auth/two-factor-auth");
      } else {
        setErrorMessage("Something went wrong");
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async () => {
    setIsLoading(true);
    setErrorMessage("");

    for (let field of ["deviceId", "email", "username", "password", "confirmPassword"]) {
      if (!values[field]?.trim()) {
        setErrorMessage(errorMessages[field]);
        setIsLoading(false);
        return;
      }
    }

    if (!validator.isEmail(values.email)) {
      setErrorMessage("Invalid email format");
      setIsLoading(false);
      return;
    }

    if (values.password !== values.confirmPassword) {
      setErrorMessage(errorMessages.passwordMismatch);
      setIsLoading(false);
      return;
    }

    if (values.deviceId !== "OD12345") {
      setErrorMessage("Invalid device ID");
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(`/register`, {
        email: values.email,
        username: values.username,
        password: values.password,
      });
      updateUserData({ email: values.email });
      navigate("/auth/verify-email");
    } catch (error) {
      console.error("Registration failed:", error);
      setErrorMessage(error?.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <nav className="navbar border-t border-t-brand-dark-blue text-brand-dark-blue w-full">
        <div className="flex flex-col sm:flex-row justify-between items-center w-full">
          <button
            className={`nav-button ${selectedForm === "login" ? "border-b-2 !font-bold" : ""
              } font-normal transition-all text-lg sm:text-xl md:text-2xl border-b border-b-brand-dark-blue w-full sm:w-1/2 py-2 sm:py-4 mb-2 sm:mb-6`}
            onClick={() => selectForm("login")}
          >
            Login
          </button>
          <button
            className={`nav-button ${selectedForm === "register" ? "border-b-2 !font-bold" : ""
              } font-normal transition-all text-lg sm:text-xl md:text-2xl border-b border-b-brand-dark-blue w-full sm:w-1/2 py-2 sm:py-4 mb-2 sm:mb-6`}
            onClick={() => selectForm("register")}
          >
            Register
          </button>
        </div>
      </nav>
      <>
        {errorMessage && (
          <div
            className="bg-[#BB1A34] bg-opacity-35 text-[#9D0000] px-4 py-3 mb-4 flex space-x-4 text-left w-4/5 font-semibold"
            role="alert"
          >
            <img src={infoIcon} alt="info icon" className="w-6 h-6" />
            <p>{errorMessage}</p>
          </div>
        )}
        {selectedForm === "login" && (
          <form
            onSubmit={handleSubmit(handleLogin)}
            className="flex flex-col w-full max-w-md"
          >
            <Toast userData={userData} updateUserData={updateUserData} />
            <InputField
              label="Username"
              name="username"
              value={values.username}
              onChange={onChange}
              register={register}
              disabled={isLoading}
            />
            <InputField
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={onChange}
              register={register}
              disabled={isLoading}
            />

            <Link
              to="/auth/forgot-password"
              className="text-brand-dark-blue font-semibold underline self-end mt-2"
            >
              Forgot Password?
            </Link>
            <Button
              variant={`${isLoading ? "secondary" : isDirty ? "primary" : "secondary"
                }`}
              className={`mt-8`}
              disabled={!isDirty || isLoading}
              submitText="Login"
              isLoading={isLoading}
            />
          </form>
        )}

        {selectedForm === "register" && (
          <form
            onSubmit={handleSubmit(handleRegister)}
            className="flex flex-col w-full max-w-md"
          >
            <InputField
              label="Device ID"
              type="text"
              name="deviceId"
              value={values.deviceId}
              onChange={onChange}
              register={register}
              disabled={isLoading}
            />
            <InputField
              label="Email"
              type="email"
              name="email"
              value={values.email}
              onChange={onChange}
              register={register}
              disabled={isLoading}
            />
            <InputField
              label="Username"
              name="username"
              value={values.username}
              onChange={onChange}
              register={register}
              disabled={isLoading}
            />

            <InputField
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={onChange}
              register={register}
              disabled={isLoading}
              placeholder="Must be at least 8 characters long"
            />

            <InputField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={onChange}
              register={register}
              disabled={isLoading}
              placeholder="Must match password"
            />
            <Button
              variant={`${isLoading ? "secondary" : isDirty ? "primary" : "secondary"
                }`}
              className={`mt-6`}
              disabled={!isDirty || isLoading}
              submitText="Create Account"
              isLoading={isLoading}
            />
          </form>
        )}
      </>
    </>
  );
};

export default Login;
