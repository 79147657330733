import "./Device.css"
import React from 'react'
import { useState } from 'react';

const ProgBar = ({currentStep, setCurrentStep, tempValue, ETA, stages, updateStages, incorrect, setIncorrect, handleIncorrect, incorrectStage, setIncorrectStage}) => {
    const testId = 123456789;
    //const stages = ['Device Connected', 'Device Temperature', 'Insert Cassette into Device', 'Input Test Details', 'Device Processing Results', 'Results Available'];
    const [tempState, setTempState] = useState('');

    
    const handleTempChange = () => 
    {
        if(tempValue > 65) {
            setTempState('Too Hot');
        } else {
            setTempState('Too Cold');
        }
    }

    const handleIncorrectFlag = () =>
    {
        if(incorrect)
        {
            setIncorrect(false);
        }
        else
        {
            setIncorrect(true);
        }

    }
    

    const captions = ['', (tempValue !== -1) ? `Temperature: ${tempValue}` : '', '', '', (currentStep === 5 && ETA !== -1) ? `Estimated Time Left: ${ETA} minutes` : '', ''];

    return (
        <div className="flex flex-col">
            <div>
            {
                stages.map((stage, i) => (

                    <div key={i} className={`step-item flex flex-row items-start ${currentStep === i+1 && "current"} ${i+1 < currentStep && "complete"} ${stage.state === 'incorrect' && "incorrect"}`}>
                        <div className='w-1/12 flex flex-col items-center'>
                            <div className="step"></div>
                            <div className={`${i < 5 && "line"}`}></div>
                        </div>

                        <div className='w-10/12 flex flex-col items-start px-4'>
                            <p className="proglabel">{stage.title}</p>
                            <p className="progcaption">{captions[i]}</p>
                        </div>

                        <div className= "w-1/12 flex flex-col items-end">{/*used for icon*/}</div>
                        
                    </div>

                ))     
            }
            </div>
            
        </div>
    )
}

export default ProgBar