import React from "react";
import { Spinner } from "./Spinner";

export const Button = ({
  submitText,
  className = "",
  type = "submit",
  onClick,
  width = "w-full",
  disabled = false,
  variant = "primary",
  isLoading=false
}) => {
  const getVariantStyle = (variant) => {
    switch (variant) {
      case "secondary":
        return "bg-[#f8f8f8] !text-[#8C8C8C] border border-[#8C8C8C]";
      case "primary":
        return "bg-brand-dark-blue !text-[#fff]";
      default:
        return "bg-brand-dark-blue !text-[#fff]";
    }
  };
  return (
    <button
      type={type}
      className={`p-4 mb-4 rounded-md flex justify-center items-center ${getVariantStyle(variant)} ${width} ${className}`}
      onClick={type !== "submit" ? onClick : undefined}
      disabled={disabled}
    >
      {isLoading ? <Spinner /> : submitText}
     
    </button>
  );
};
