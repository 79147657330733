import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Results.css";
import PDFFile from "./PDFFile";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadIcon from "../resources/download.svg";
import UploadIcon from "../resources/upload.svg";
import ShareResultModal from "./Modal.jsx";
import Search from "../common/Search.jsx";
import axios from "../../api/axios.js";
import { formatDateMMDDYYYY } from "../../utils/date.js";
import usePageTitle from "../../hooks/usePageTitle.js";

const resultsHeaders = [
  "Patient Name",
  "Test Number",
  "Test Type",
  "Lab/Hospital",
  "Doctor",
  "Date Issued",
  " ",
];

const filterResults = (query, results) => {
  const terms = query.split(" ");
  return results?.filter((result) => {
    const searchableFields = {
      fullName: result.full_name,
      testNumber: String(result.requisition_no),
      testType: result.type_of_test,
      clinician: result.ordering_clinician,
      labOrHospital: result.referring_institution,
      dateIssued: result.date_requested,
    };
    return terms.every((term) =>
      Object.values(searchableFields).some((value) =>
        String(value).toLowerCase().includes(term.toLowerCase())
      )
    );
  });
};

export const Results = () => {
  usePageTitle("Results");
  const navigate = useNavigate();

  const [results, setResults] = useState();

  const [openModal, setOpenModal] = useState({
    isOpen: false,
    copyTo: null,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const shareResult = (copyTo) => {
    setOpenModal({ isOpen: true, copyTo });
  };

  const closeModal = () => {
    setOpenModal({ isOpen: false, copyTo: null });
  };

  const fetchResults = async () => {
    try {
      const response = await axios.get("/tests");
      setResults(response.data.tests);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchResults();
  }, []);

  const filteredResults = filterResults(searchQuery, results);

  return (
    <div className="results-container">
      <div className="results-header">
        <p className="results-title">Test Results History</p>
        <div className="results-search">
          <Search setSearchQuery={setSearchQuery} />
        </div>
      </div>

      <div className="results-table-container">
        <table className="results-table">
          <thead>
            <tr>
              {resultsHeaders.map((header, index) => {
                return (
                  <th key={index} className="table-header">
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {filteredResults?.length ? (
              filteredResults?.map((result) => {
                const {
                  type_of_test,
                  referring_institution,
                  ordering_clinician,
                  date_of_birth,
                  full_name,
                  requisition_no,
                  _id: test_id,
                  copy_to,
                } = result;
                return (
                  <tr
                    key={test_id}
                    className="table-row"
                    onClick={() => navigate(`/results/detail/${test_id}`)}
                  >
                    <td className="table-cell">{full_name}</td>
                    <td className="table-cell">{requisition_no}</td>
                    <td className="table-cell">{type_of_test}</td>
                    <td className="table-cell">{referring_institution}</td>
                    <td className="table-cell">{ordering_clinician}</td>
                    <td className="table-cell">
                      {formatDateMMDDYYYY(date_of_birth)}
                    </td>
                    <td className="table-cell">
                      <div className="action-buttons">
                        <PDFDownloadLink
                          document={<PDFFile />}
                          fileName="ProbeiT_test_results.pdf"
                        >
                          <div className="action-button">
                            <img
                              src={DownloadIcon}
                              alt="download-icon"
                              className="icon"
                            />
                            <span>Download</span>
                          </div>
                        </PDFDownloadLink>

                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            shareResult(copy_to);
                          }}
                          className="action-button"
                        >
                          <img
                            src={UploadIcon}
                            alt="share-icon"
                            className="icon"
                          />
                          <span>Share</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="no-results">
                  No results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ShareResultModal
        open={openModal.isOpen}
        onClose={closeModal}
        copyTo={openModal.copyTo}
      />
    </div>
  );
};

export default Results;
