import React from "react";
import { useForm } from "react-hook-form";
import ErrorAlert from "./ErrorAlert";
import { Button } from "./Button";

export const OTPForm = ({
  onSubmit,
  isLoading,
  btnText,
  input,
  inputRefs,
  setInput,
  errorMessage,
  setErrorMessage,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: { ...input },
  });

  const handleInputChange = (index, e) => {
    setErrorMessage("");
    const target = e.target;
    let value = target.value;
    const name = target.name;
    if (value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    if (value.length > 1) value = value[0];

    if (isNaN(value)) value = "";

    setInput((fields) => ({
      ...fields,
      [name]: value,
    }));
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasted = e.clipboardData.getData("text/plain");
    const split = pasted.split("");
    if (split.length > 4 || split.some((c) => isNaN(c))) return;
    split.forEach((c, i) => {
      if (i <= inputRefs.current.length - 1) {
        setInput((fields) => ({
          ...fields,
          [inputRefs.current[i].name]: c,
        }));
        setValue(inputRefs.current[i].name, c, { shouldDirty: true });
        inputRefs.current[i].focus();
      }
    });
  };

  const focusInput = (targetIndex, isDelete) => {
    const targetInput = inputRefs.current[targetIndex];
    if (targetInput) {
      targetInput.focus();
      targetInput.select();
      if (isDelete) {
        setInput((fields) => ({
          ...fields,
          [inputRefs.current[targetIndex].name]: "",
        }));
        const allInputs = Object.values(input);
        const allFieldsEmpty =
          allInputs.filter((item) => item === "").length ===
          allInputs.length - 1;
        if (allFieldsEmpty) {
          clearInputs();
        }
      }
    }
  };

  const clearInputs = () => {
    reset({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
    setInput({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
  };

  const handleKeyDown = (index, e) => {
    switch (e.key) {
      case "Backspace":
        if (e.target.value === "" && index > 0) {
          e.preventDefault();
          focusInput(index - 1, true);
        }
        break;
      case "ArrowLeft":
        if (index > 0) {
          e.preventDefault();
          focusInput(index - 1);
        }
        break;
      case "ArrowRight":
        if (index < inputRefs.current.length - 1) {
          e.preventDefault();
          focusInput(index + 1);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center px-4 sm:px-6 h-full"
      >
        {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
        <p className="w-full font-roboto text-base sm:text-lg text-gray-400 text-center font-normal mt-6 sm:mt-10">
          Enter the verification code we just sent to your email address.
        </p>

        <div className="flex justify-center py-6 sm:py-8">
          {Array(4)
            .fill()
            .map((_, index) => {
              return (
                <OTPInputField
                  key={index}
                  name={`field_${index + 1}`}
                  onChange={(e) => handleInputChange(index, e)}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  onPaste={handlePaste}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  value={input[`field_${index + 1}`]}
                  register={register}
                  disabled={isLoading}
                />
              );
            })}
        </div>
        <Button
          width="w-full sm:w-1/2"
          variant={`${isLoading ? "secondary" : isDirty ? "primary" : "secondary"
            }`}
          className="mt-6"
          disabled={!isDirty || isLoading}
          submitText={btnText}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

// Define OTPInputField within the same file
const OTPInputField = ({
  name,
  onChange,
  inputRef,
  value,
  register,
  disabled = false,
  onKeyDown,
  onPaste,
}) => {
  return (
    <input
      type="text"
      {...register(name, {
        onChange: onChange,
      })}
      onPaste={onPaste}
      onKeyDown={onKeyDown}
      name={name}
      ref={inputRef}
      value={value}
      disabled={disabled}
      maxLength={1}
      autoComplete="off"
      className="w-12 h-12 sm:w-16 sm:h-16 text-center text-xl sm:text-2xl border border-gray-300 rounded-md mx-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  );
};

export default OTPForm;
