import React from "react";
import Modal from "../common/Modal";
import InputField from "../Login/components/InputField";
import { Button } from "../common/Button";
import googledrive from "../resources/googledrive.png";
import onedrive from "../resources/onedrive.png";
import dropbox from "../resources/dropbox.png";
import { useForm } from "react-hook-form";

const ShareResultModal = ({ open, onClose, copyTo }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues: { email: "" } });
  return (
    <Modal open={open === true} onClose={onClose}>
      <div className="text-left w-[40vw] flex flex-col text-brand-dark-blue">
        <p className="pb-2 w-full border-b border-brand-dark-blue text-lg font-bold px-6">
          Share Results
        </p>
        <div className="w-full p-6">
          <InputField
            label="Email Address:"
            value={copyTo}
            onChange={undefined}
            register={register}
            name="email"
            orientation="row"
          />
          <div className="flex w-full mt-4">
            <div className="flex flex-grow w-full"></div>
            <Button
              type="button"
              submitText="Send Results"
              width="w-[40%]"
            />
          </div>
          <div className="border-b border-brand-dark-blue px-6"></div>
        </div>
        <div className="flex items-center gap-4 p-6">
          <p className="text-brand-dark-blue font-semibold">Upload to: </p>
          <div className="flex space-x-16">
            <img
              src={googledrive}
              alt="google drive logo"
              className="w-20 h-20 cursor-pointer"
            />
            <img
              src={onedrive}
              alt=" onedrive logo"
              className="w-20 h-20 cursor-pointer"
            />
            <img
              src={dropbox}
              alt="dropbox logo"
              className="w-20 h-20 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareResultModal;
