import { format, parse } from "date-fns";

export const formatDateMMDDYYYYHHMMA = (date) => {
  return format(new Date(date), "MM/dd/yyyy, hh:mm a");
};
export const formatDateMMDDYYYY = (date) => {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());

  return format(new Date(parsedDate), "MM/dd/yyyy");
};
