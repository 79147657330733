import React from 'react';
import {Page, Text, View, Image, Document, StyleSheet, Svg, Line} from '@react-pdf/renderer';
import logo from '../resources/ProbeIT.png';
import ReactDOM from 'react-dom';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

const styles = StyleSheet.create({

    header: {
        fontSize: "25px",
        textAlign: "center",
        color: "#F03D3E",
        //fontFamily: "Roboto, sans-serif",
        fontWeight: "bold"
        
      },
      subheader: {
        fontSize: 20,
        textAlign: "center",
        color: "#F03D3E",
        //fontFamily: "Roboto, sans-serif",
        fontWeight: "bold",
        padding: 10
      },
      fieldlabel: {
        fontSize: 9,
        textAlign: "left",
        color: "#F03D3E",
        //fontFamily: "Roboto, sans-serif"
      },
      fieldsheader: {
        fontSize: 11,
        color: "black",
        //fontFamily: "Roboto, sans-serif",
        fontWeight: "bold"
      },
      fieldtext: {
        fontSize: 9,
        color: "black",
        //fontFamily: "Roboto, sans-serif"
      },
      page: {
        padding: 50
      },
      row: {
        display: "flex",
        flexDirection: "row",
      },
      column: {
        display: "flex",
        flexDirection: "column"
      },
      image: {
        width: 150
      },
      tableheadertext: {
        color: "#1764ad",
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "left"
      },
      tabledivider: {
        borderRightWidth: "1px",
        borderRightColor: "#495057"
       },
      tableheader:
      {
        height: "auto",
        backgroundColor: "#52D1FF",
        color: "white",
        fontSize: "10px",
        fontWeight: "bold",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      tableroweven:
      {
        height: "auto",
        backgroundColor: "#E9F6FD",
        color: "#495057",
        fontSize: "10px",
        fontWeight: "bold",
        borderBottomColor: "#495057",
        borderBottomWidth: "1px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
      },
      tablerowodd:
      {
        height: "auto",
        backgroundColor: "#F9F9F9",
        color: "#495057",
        fontSize: "10px",
        fontWeight: "bold",
        borderBottomColor: "#495057",
        borderBottomWidth: "1px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center"
      }
});


const PDFFile = () =>
{

    const patientdetails = ["Full Name:", "Requesting Healthcare Provider:", "Date of Birth:", "Health Card No:", "Requisition No:", "Phone:", "Address:", "Ordering Clinician:", "Health Plan Beneficiary No.", "Medical Record No."];
    const testdetails = ["Test ID:", "Referring Institution:", "Type of Test", "Copy to:", "Data Requested:", "Time of Sample Collection:", "Time Received:", "Time Reported:"];
  
    
    const gene_example_data = [
        { gene: "CYP2D6", variant: "*1, *1x2, *1x≥3, *2, *2x2, *2x≥3, *3, *4, *5, *6, *9, *10, *41" },
        { gene: "SLCO1B", variant: "*1, *5, *6, *7, *10, *14, *15, *43" },
        { gene: "CYP2C19", variant: "*1, *2, *3, *4, *5, *6, *7, *8, *9, *10, *12, *13, *15, *17, *35" }
      ];

    const test_detail_summary = [
        {gene: "CYP2D6", variant: "*1/*1", phenotype: "Normal Metabolizer", overview: "Standard dosing recommended for drugs metabolized by CYP2D6."},
        {gene: "CYP2C19", variant: "*2/*2", phenotype: "Poor Metabolizer", overview: "Caution: Increased risk of adverse effects with drugs metabolized by CYP2C19."},
        {gene: "VKORC1", variant: "AA", phenotype: "Normal Sensitivity", overview: "Standard dosing for drugs like warfarin is appropriate."},
        {gene: "MTHFR", variant: "TT", phenotype: "Normal", overview: "No increased risk for methotrexate toxicity."},
        {gene: "CYP2D6", variant: "*1/*1", phenotype: "Normal Metabolizer", overview: "Standard dosing recommended for drugs metabolized by CYP2D6."},
        {gene: "CYP2C19", variant: "*2/*2", phenotype: "Poor Metabolizer", overview: "Caution: Increased risk of adverse effects with drugs metabolized by CYP2C19."},
        {gene: "VKORC1", variant: "AA", phenotype: "Normal Sensitivity", overview: "Standard dosing for drugs like warfarin is appropriate."},
        {gene: "MTHFR", variant: "TT", phenotype: "Normal", overview: "No increased risk for methotrexate toxicity."}
    ];

    const recommendations_example = [
        {drugs: "Amitriptyline", status: "Actionable", cpic: "CYP2D6 Normal Metabolizer", cpic_text: "Initiate therapy with recommended starting dose. Patients may receive an initial low dose of a tricyclic, which is then increased over several days to the recommended steady-state dose. The starting dose in this guideline refers to the recommended steady-state dose.", dpwg: "CYP2D6 Normal Metabolizer", dpwg_text: "No specific recommendations based on genotype have been developed."},
        {drugs: "Amoxapine", status: "Actionable", cpic: "CYP2D6 Normal Metabolizer", cpic_text: "Initiate therapy with the recommended starting dose for normal metabolizers.", dpwg: "CYP2D6 Normal Metabolizer", dpwg_text: "No specific recommendations based on genotype have been developed."},
        {drugs: "Amphetamine", status: "Informative", cpic: "CYP2D6 Normal Metabolizer", cpic_text: "Initiate therapy with recommended starting dose.", dpwg: "CYP2D6 Normal Metabolizer", dpwg_text: "No specific recommendations based on genotype have been developed."},
        {drugs: "Aripiprazole", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Aripiprazole Lauroxil", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Brexpiprazole", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Bupropion", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Cariprazine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Citalopram", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Clozapine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Desipramine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Desvenlafaxine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Doxepin", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Duloxetine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Escitalopram", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Fluoxetine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Fluvoxamine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Iloperidone", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Imipramine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Modafinil", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Nefazodone", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Nortriptyline", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Paliperidone", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Paroxetine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Perphenazine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Pimozide", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Pitolisant", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Protriptyline", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Risperidone", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Thioridazine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Trimipramine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Venlafaxine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Viloxazine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},
        {drugs: "Vortioxetine", status: "null", cpic: "null", cpic_text: "null", dpwg: "null", dpwg_text: "null"},

    ];
    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.column}>
                  <View style={{...styles.row, justifyContent: "space-around", paddingBottom: 16}}>
                    <Image style={styles.image} src={logo} />
                    <View style={{...styles.column, paddingLeft: 30}}>
                      <Text style={{...styles.fieldsheader, paddingBottom: 2}}>Patient Information</Text>
                      {patientdetails.map((field, i) => {       
                        if(i<5)
                        {return( <View style={{...styles.row, paddingVertical: 2}}>
                            <Text style={{...styles.fieldlabel, paddingRight: 4}}>{field}</Text>
                            <Text style={styles.fieldtext}>test_value</Text>
                            </View>);}})
                      }
                    </View>
                    <View style={{...styles.column, marginTop: 14, paddingLeft: 16}}>
                      {patientdetails.map((field, i) => {       
                        if(i>=5)
                        {return( <View style={{...styles.row, paddingVertical: 2}}>
                            <Text style={{...styles.fieldlabel, paddingRight: 4}}>{field}</Text>
                            <Text style={styles.fieldtext}>test_value</Text>
                            </View>);}})
                      }
                    </View>
                  </View>
                  <Text style={styles.header}>Pharmacogenetic Test Result</Text>
                  <Text style={{...styles.subheader, paddingBottom: 16}}>Psychiatric Test Panel</Text> 
                
                  <Text style={styles.tableheadertext}>Genetic Variants Analyzed</Text>
                  <Svg height="10" width="500">
                    <Line x1="0" y1="0" x2="500" y2="0" strokeWidth={1} stroke="black"/>
                  </Svg>

                  <View style={{paddingBottom: 16}}>
                    <View style={styles.row}>
                        <View style={{...styles.tableheader,width: "40%", borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>Gene</Text></View>
                        <View style={{...styles.tableheader,width: "60%"}}><Text>Genetic Variant</Text></View>
                    </View>
                    {gene_example_data.map((row, i) => {
                      if(i%2 === 0)
                      {
                        return( <View style={styles.row}>
                            <View style={{...styles.tableroweven,width:"40%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.gene}</Text></View>
                            <View style={{...styles.tableroweven,width:"60%", paddingVertical: 2}}><Text>{row.variant}</Text></View>
                          </View>);
                        }
                        else
                        {
                          return (<View style={styles.row}>
                              <View style={{...styles.tablerowodd,width:"40%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.gene}</Text></View>
                              <View style={{...styles.tablerowodd,width:"60%", paddingVertical: 2}}><Text>{row.variant}</Text></View>
                            </View>);

                        }
                        
})}
                  </View>


                  <Text style={styles.tableheadertext}>Test Detail Summary</Text>
                  <Svg height="10" width="500">
                    <Line x1="0" y1="0" x2="500" y2="0" strokeWidth={1} stroke="black"/>
                  </Svg>

                  <View style={{paddingBottom: 16}}>
                    <View style={styles.row}>
                        <View style={{...styles.tableheader,width: "15%", borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>Gene</Text></View>
                        <View style={{...styles.tableheader,width: "10%", borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>Variant</Text></View>
                        <View style={{...styles.tableheader,width: "25%", borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>Phenotype</Text></View>
                        <View style={{...styles.tableheader,width: "50%"}}><Text>Overview</Text></View>
                    </View>
                    {test_detail_summary.map((row, i) => {
                      if(i%2 === 0)
                      {
                        return( <View style={styles.row}>
                            <View style={{...styles.tableroweven,width:"15%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.gene}</Text></View>
                            <View style={{...styles.tableroweven,width:"10%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.variant}</Text></View>
                            <View style={{...styles.tableroweven,width:"25%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.phenotype}</Text></View>
                            <View style={{...styles.tableroweven,width:"50%", fontSize: 8, padding: 2, paddingVertical: 4}}><Text style={{textAlign: "left"}}>{row.overview}</Text></View>
                          </View>);
                        }
                        else
                        {
                          return (<View style={styles.row}>
                              <View style={{...styles.tablerowodd,width:"15%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.gene}</Text></View>
                              <View style={{...styles.tablerowodd,width:"10%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.variant}</Text></View>
                              <View style={{...styles.tablerowodd,width:"25%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.phenotype}</Text></View>
                              <View style={{...styles.tablerowodd,width:"50%", fontSize: 8, padding: 2, paddingVertical: 4}}><Text style={{textAlign: "left"}}>{row.overview}</Text></View>
                            </View>);
                        }
})}
                  </View>

                  <Text style={styles.tableheadertext}>Therapeutic Recommendations</Text>
                  <Svg height="10" width="500">
                    <Line x1="0" y1="0" x2="500" y2="0" strokeWidth={1} stroke="black"/>
                  </Svg>

                  <View style={{paddingBottom: 16}}>
                    <View style={styles.row}>
                        <View style={{...styles.tableheader,width: "17%", borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>Drugs</Text></View>
                        <View style={{...styles.tableheader,width: "17%", borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>Status</Text></View>
                        <View style={{...styles.tableheader,width: "33%", borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>CPIC</Text></View>
                        <View style={{...styles.tableheader,width: "33%"}}><Text>DPWG</Text></View>
                    </View>

                    {recommendations_example.map((row, i) => {
                      if(i%2 === 0)
                      {
                        return( <View style={{...styles.row, height: "auto"}}>
                            <View style={{...styles.tableroweven,width:"17%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.drugs}</Text></View>
                            <View style={{...styles.tableroweven,width:"17%",borderRightWidth: "1px",borderRightColor: "#495057", color: "#F03D3E"}}><Text>{row.status}</Text></View>
                            <View style={{...styles.tableroweven,width:"33%",borderRightWidth: "1px",borderRightColor: "#495057"}}>
                              <View style={{...styles.column, height: "auto", paddingVertical: 6, paddingHorizontal: 2}}>
                                <Text style={{ color: "#1764ad", fontWeight: "bold", fontSize: 12, textAlign: "center", paddingBottom: 4}}>{row.cpic}</Text>
                                <Text style={{fontSize: 10, textAlign: "left"}}>{row.cpic_text}</Text>
                              </View>
                            </View>
                            <View style={{...styles.tableroweven,width:"33%"}}>
                              <View style={{...styles.column, height: "auto", paddingVertical: 6, paddingHorizontal: 2}}>
                                <Text style={{ color: "#1764ad", fontWeight: "bold", fontSize: 12, textAlign: "center", paddingBottom: 4}}>{row.dpwg}</Text>
                                <Text style={{fontSize: 10, textAlign: "left"}}>{row.dpwg_text}</Text>
                              </View>
                            </View>
                          </View>);
                        }
                        else
                        {
                          return (<View style={styles.row}>
                              <View style={{...styles.tablerowodd,width:"17%",borderRightWidth: "1px",borderRightColor: "#495057"}}><Text>{row.drugs}</Text></View>
                              <View style={{...styles.tablerowodd,width:"17%",borderRightWidth: "1px",borderRightColor: "#495057", color: "#F03D3E"}}><Text>{row.status}</Text></View>
                              <View style={{...styles.tablerowodd,width:"33%",borderRightWidth: "1px",borderRightColor: "#495057"}}>
                              <View style={{...styles.column, height: "auto", paddingVertical: 6, paddingHorizontal: 2}}>
                                <Text style={{ color: "#1764ad", fontWeight: "bold", fontSize: 12, textAlign: "center", paddingBottom: 4}}>{row.cpic}</Text>
                                <Text style={{fontSize: 10, textAlign: "left"}}>{row.cpic_text}</Text>
                              </View>
                              </View>
                              <View style={{...styles.tablerowodd,width:"33%"}}>
                                <View style={{...styles.column, height: "auto", paddingVertical: 6, paddingHorizontal: 2}}>
                                  <Text style={{ color: "#1764ad", fontWeight: "bold", fontSize: 12, textAlign: "center", paddingBottom: 4}}>{row.dpwg}</Text>
                                  <Text style={{fontSize: 10, textAlign: "left"}}>{row.dpwg_text}</Text>
                                </View>
                              </View>
                            </View>);
                        }
})}
                  </View>              
                </View>
            </Page>
        </Document>

    );

}

export default PDFFile;