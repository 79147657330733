import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../resources/ProbeIT.png";
import powerButtonOn from "../resources/power-button.png";
import powerButtonOff from "../resources/power-button-off.png";

import "./Navbar.css";
import { useUser } from "../Login/UserContext";

export const Navbar = ({ deviceStarted, handleDevice }) => {
  const { logout } = useUser();
  return (
    <div className="w-full flex flex-wrap items-center justify-between border-b border-b-brand-dark-blue px-4 sm:px-6 lg:px-8">
      <img
        src={logo}
        alt="probeit-logo"
        className="w-24 h-24 object-contain"
      />

      <nav className="flex flex-wrap items-center space-x-4 sm:space-x-6 lg:space-x-8 text-base sm:text-lg">
        <ul className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-6 lg:space-x-8">
          <li>
            <NavLink to="/" className="nav-link" activeClassName="active">
              Test
            </NavLink>
          </li>
          <li>
            <NavLink to="/results" className="nav-link" activeClassName="active">
              Results
            </NavLink>
          </li>
          <li>
            <NavLink to="/auth" onClick={logout} className="nav-link">
              Log Out
            </NavLink>
          </li>
        </ul>
        <button
          onClick={handleDevice}
          className={`mt-2 sm:mt-0 flex items-center space-x-2 sm:space-x-3 rounded-md py-2 px-4 sm:py-3 sm:px-6 font-normal border border-[#8C8C8C] ${deviceStarted
              ? "bg-brand-dark-blue text-white"
              : "bg-[#f8f8f8] text-[#8C8C8C]"
            }`}
        >
          <img
            src={deviceStarted ? powerButtonOn : powerButtonOff}
            alt="device-power-button"
            className="h-5 sm:h-6 object-contain"
          />
          <span>{deviceStarted ? "Stop" : "Start"} Device</span>
        </button>
      </nav>
    </div>
  );
};
  // return (
  //   <div className="w-full flex justify-between border-b border-b-brand-dark-blue px-32">
  //     <img
  //       src={logo}
  //       alt="probeit-logo"
  //       className="w-[140px] h-[140px] object-cover ml-[-30px]"
  //     />

  //     <nav className="flex space-x-20 text-[20px]  mt-10">
  //       <ul className="flex space-x-20">
  //         <li>
  //           <NavLink to="/">Test</NavLink>
  //         </li>
  //         <li>
  //           <NavLink to="/results">Results</NavLink>
  //         </li>
  //         <li>
  //           <NavLink to="/auth" onClick={logout} >Log Out</NavLink>
  //         </li>
  //       </ul>
  //       <NavLink onClick={handleDevice}>
  //         <button
  //           className={`flex flex-row items-center space-x-3 rounded-md py-3 px-8 font-normal border border-[#8C8C8C] ${deviceStarted
  //               ? "bg-brand-dark-blue text-white"
  //               : "bg-[#f8f8f8] !text-[#8C8C8C]" 
  //             }`}
  //         >
  //           <img
  //             src={deviceStarted ? powerButtonOn : powerButtonOff}
  //             alt="device-power-button"
  //             className="h-6 object-cover"
  //           />
  //           <span> {deviceStarted ? "Stop" : "Start"} Device</span>
  //         </button>
  //       </NavLink>
  //     </nav>
  //   </div>
  // );
// };

export default Navbar;
