import { useEffect, useState } from "react";
import successful from "../../resources/successful.png";

const Toast = ({ userData, updateUserData }) => {
  const message = userData.message;
  const [show, setShow] = useState(message ? true : false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      updateUserData({ message: "" });
    }, 5000);
  
    return () => clearTimeout(timer);
  }, []);
  

  return (
    <div
      className={`${
        show ? "flex" : "hidden"
      } flex justify-between items-center font-bold text-[18px] bg-[#C7EECD] text-center p-4 border w-full !mb-4 text-[#009D19]`}
    >
      <div className="flex justify-center items-center space-x-4">
        <img src={successful} alt="info icon" className="w-6 h-6" />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Toast;
