import React from "react";
import InputField from "../Login/components/InputField";

const TestDetailsForm = ({ testDetails, onChange, isEditing, register, isLoading }) => {
  const inputFields = [
    { label: "Full Name:", name: "fullName" },
    { label: "Date of Birth:", name: "dateOfBirth", type: "date" },
    { label: "Phone Number:", name: "phoneNumber" },
    { label: "Health Plan Beneficiary:", name: "healthPlanBeneficiaryNumber" },
    { label: "Address:", name: "address" },
    { label: "Requisition Number:", name: "requisitionNumber" },
    { label: "Referring Institution:", name: "referringInstitution" },
    { label: "Name of Ordering Clinician:", name: "nameOfOrderingClinician" },
    { label: "Medical Record Number:", name: "medicalRecordNumber" },
    { label: "Provider Email:", name: "providerEmail" },
    { label: "Requesting Healthcare Provider:", name: "requestingHealthCareProvider" },
    { label: "Sample Collected:", name: "timeOfSampleCollection", type: "datetime-local" },
    { label: "Health Card Number:", name: "healthCardNumber" },
    { label: "Test Initiated:", name: "timeReceived", type: "datetime-local", disabled: isEditing || isLoading },
    { label: "Type of Test:", name: "typeOfTest" },
  ];

  return (
    <>
      {inputFields.map((field) => (
        <InputField
          style={{ width: '100%', minWidth: '200px' }} 
          key={field.name}
          label={field.label}
          value={testDetails[field.name]}
          onChange={onChange}
          name={field.name}
          type={field.type || "text"} // Default to "text" if type is not specified
          register={register}
          disabled={field.disabled || isLoading}
          className="w-full  min-w-[200px]"
        />
      ))}
    </>
  );
};

export default TestDetailsForm;
