import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Device.css";
import logo from "../resources/ProbeIT-blue.png";
import Navbar from "../Navbar/Navbar.jsx";
import ProgBar from "./ProgBar.jsx";
import {
  IncorrectStage1,
  Stage2,
  IncorrectStage2,
  Stage3,
  Stage4,
  Stage5,
  Stage6,
} from "./Stages.jsx";
import { Button } from "../common/Button.jsx";

const Device = () => {
  const navigate = useNavigate();
  const [tempValue, setTempValue] = useState(65);
  const testId = 123456789;

  const tempState =
    tempValue > 65 ? "Too Hot" : tempValue < 60 ? "Too Cold" : "Just Right";
  const [ETA, setETA] = useState(12);
  const [currentStep, setCurrentStep] = useState(2);

  const initialStageList = [
    {
      current: "Device Connected",
      complete: "Device Connected",
      title: "Device Connected",
      incorrect: "Device Disconnected",
      state: "complete",
    },
    {
      current: "Device Heating Up",
      complete: `Device Temperature: ${tempState}`,
      title: "Device Heating Up",
      incorrect:
        tempValue !== -1
          ? `Device Temperature: ${tempState}`
          : "Device Temperature: ",
      state: "complete",
    },
    {
      current: "Input Test Details",
      complete: `Saved Details for Test #${testId}`,
      title: "Input Test Details",
      incorrect: " ",
      state: "current",
    },
    {
      current: "Insert Cassette into Device",
      complete: "Cassette Inserted",
      title: "Insert Cassette into Device",
      incorrect: "Cassette Inserted Incorrectly",
      state: "inactive",
    },
    {
      current: "Processing Results",
      complete: "Results Processed",
      title: "Device Processing Results",
      incorrect: " ",
      state: "inactive",
    },
    {
      current: "Review Test Details",
      complete: "Results Available",
      title: "Review Test Details",
      incorrect: " ",
      state: "inactive",
    },
  ];
  const [stages, setStages] = useState(initialStageList);
  const [incorrect, setIncorrect] = useState(false);
  const [incorrectStage, setIncorrectStage] = useState(1);

  function updateStages(newCurrentStep) {
    setCurrentStep(newCurrentStep);

    setStages(
      stages.map((stage, i) => {
        if (newCurrentStep === i + 1) {
          return { ...stage, state: "current", title: stage.current };
        } else if (i + 1 <= newCurrentStep) {
          return { ...stage, state: "complete", title: stage.complete };
        } else {
          return stage;
        }
      })
    );
  }

  function handleIncorrect() {
    if (incorrect) {
      setIncorrectStage(incorrectStage + 1);
      if (incorrectStage === 3) {
        setIncorrectStage(1);
      }
      setCurrentStep(incorrectStage);

      setStages(
        stages.map((stage, i) => {
          if (incorrectStage === i + 1) {
            return { ...stage, state: "incorrect", title: stage.incorrect };
          } else if (i + 1 <= incorrectStage) {
            return { ...stage, state: "complete", title: stage.incorrect };
          } else {
            return { ...stage, state: "inactive", title: stage.complete };
          }
        })
      );
    }
  }

  const getButtonText = (step) => {
    switch (step) {
      case 4:
        return "Run Test";
      case 6:
        return "Confirm";
      default:
        return "Next";
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col md:flex-row overflow-hidden">
      {/* Left Section */}
      <div className="bg w-full md:w-5/12 min-h-screen flex flex-col">
        <div className="py-8 md:py-12">
          <ProgBar
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            tempValue={tempValue}
            ETA={ETA}
            stages={stages}
            updateStages={updateStages}
            incorrect={incorrect}
            setIncorrect={setIncorrect}
            handleIncorrect={handleIncorrect}
            incorrectStage={incorrectStage}
            setIncorrectStage={setIncorrectStage}
          />
        </div>
      </div>

      {/* Right Section */}
      {/* <div className="w-7/12 min-h-screen flex flex-col">
        <div className="flex flex-col items-center h-full w-full pr-32"> */}
      <div className="w-full md:w-7/12 min-h-screen flex flex-col">
        <div className="flex flex-col items-center h-full w-full md:pr-32">
          <div className=" flex flex-col items-center h-full w-full md:pl-20">
            <div className="titleblock items-center justify-center pt-7">
              <h1 className="titletxt">{stages[currentStep - 1].title}</h1>
            </div>
            {/* conditional rendering */}
            {currentStep === 1 && <IncorrectStage1 />} {/*Stage 1 disconnect*/}
            {currentStep === 2 && stages[2].state === "incorrect" && (
              <Stage2 tempValue={tempValue} />
            )}
            {currentStep === 2 && stages[2].state !== "incorrect" && (
              <Stage2 tempValue={tempValue} />
            )}
            {currentStep === 3 && <Stage3 currentStep={currentStep} updateStages={updateStages} />}
            {currentStep === 4 && <Stage4 />}
            {currentStep === 5 && <Stage5 eta={ETA} />}
            {currentStep === 6 && (
              <Stage6 currentStep={currentStep} updateStages={updateStages} />
            )}
            {/*<IncorrectStage2 tempValue={tempValue}/>*/}
            {[3, 6].includes(currentStep) || (
              <Button
                submitText={getButtonText(currentStep)}
                type="button"
                onClick={() => updateStages(currentStep + 1)}
                width="w-72"
                className="mt-6"
              />
            )}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Device;
