import infoIcon from '../resources/info-red.png'

const ErrorAlert = ({ errorMessage }) => {
  return (
    <div
      className="bg-[#BB1A34] bg-opacity-35 text-[#9D0000] px-4 py-3 mb-4 flex space-x-4 text-left w-4/5 font-semibold"
      role="alert"
    >
      <img src={infoIcon} alt="info icon" className="w-6 h-6" />
      <p>{errorMessage}</p>
    </div>
  );
};

export default ErrorAlert;
