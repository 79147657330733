import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:3000";

axios.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    console.log("axios", {token})
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data.error === 'jwt expired') {
      localStorage.removeItem('user');
      localStorage.removeItem("token");
      alert("Session expired. Please log in again.");
      window.location.href = "/auth";  // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axios;