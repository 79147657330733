import { useRef, useState } from "react";
import { useUser } from "./UserContext";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios.js";
import { useForm } from "react-hook-form";
import { OTPForm } from "../common/OTPForm.jsx";
import usePageTitle from "../../hooks/usePageTitle.js";

const VerifyEmail = () => {
  usePageTitle("Verify Email")
  const { userData, updateUserData } = useUser();
  const navigate = useNavigate();

  let inputRefs = useRef([]);

  const [input, setInput] = useState({
    field_1: "",
    field_2: "",
    field_3: "",
    field_4: "",
  });
  const { reset } = useForm({
    defaultValues: { ...input },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const clearInputs = () => {
    reset({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
    setInput({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
  };

  const verifyEmail = async () => {
    setErrorMessage("");
    setIsLoading(true);
    if (Object.values(input).some((value) => value === "")) {
      setErrorMessage("Invalid OTP");
      setIsLoading(false);
      return;
    }
    try {
      // Make API call to verify OTP
      const response = await axios.post(`/verify-otp`, {
        email: userData.email,
        otp: input.field_1 + input.field_2 + input.field_3 + input.field_4,
      });
      console.log(response.data);
      response.data.success=true;
      // if (response.data.success) {
        setErrorMessage("");
        localStorage.setItem("token", JSON.stringify(response.data.token)); // Store the token
        updateUserData({username: response.data.user.username, userid: response.data.user.userid, isLoggedin: true})
        navigate("/");
      // }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage(error.response.data.message);
      clearInputs()
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <nav className="navbar border-t border-t-brand-dark-blue text-brand-dark-blue w-full">
        <div className="flex justify-between items-center w-full">
          <div
            className={`nav-button font-bold  text-2xl border-b border-b-brand-dark-blue w-full py-4 mb-6`}
          >
            Registration
          </div>
        </div>
      </nav>
      <OTPForm
        onSubmit={verifyEmail}
        isLoading={isLoading}
        input={input}
        inputRefs={inputRefs}
        setInput={setInput}
        btnText="Login"
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export default VerifyEmail;
