import { x } from "react-icons-kit/feather/x";
import Icon from "react-icons-kit";
import { useEffect, useState } from "react";
import successful from "../resources/successful.png";

const Toast = () => {
  const [show, setShow] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShow(false);
      }, 5000);
      return () => setTimeout(timer);
    }, []);

  return (
    <div
      className={`${
        show ? "flex" : "hidden"
      } shadow-2xl flex justify-between items-center text-[20px] bg-[#C7EECD] text-center p-6 border w-[30%] !mb-4 text-[#009D19] rounded-lg absolute top-40 right-44`}
    >
      <div className="flex justify-center items-center space-x-4">
        <img src={successful} alt="info icon" className="w-6 h-6" />
        <p> Changes saved</p>
      </div>
      <button type="button" onClick={() => setShow(false)} className="!ml-12 text-[#1A1A1A]">
        <Icon icon={x} size={25} />
      </button>
    </div>
  );
};

export default Toast;
