import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import "./Results.css";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/feather/chevronLeft";
import { chevronDown } from "react-icons-kit/feather/chevronDown";
import { chevronUp } from "react-icons-kit/feather";
import DownloadIcon from "../resources/download.svg";
import UploadIcon from "../resources/upload.svg";
import ShareResultModal from "./Modal";
import Toast from "./Toast";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFFile from "./PDFFile";
import axios from "../../api/axios.js";
import {
  formatDateMMDDYYYY,
  formatDateMMDDYYYYHHMMA,
} from "../../utils/date.js";
import { Spinner } from "../common/Spinner.jsx";
import usePageTitle from "../../hooks/usePageTitle.js";

const tabs = [
  "Patient Details",
  "Test Details",
  "Analyzed Genes",
  "Test Detail Summary",
  "Therapeutic Recommendations",
];

const gene_example_data = [
  {
    gene: "CYP2D6",
    variant: "*1, *1x2, *1x≥3, *2, *2x2, *2x≥3, *3, *4, *5, *6, *9, *10, *41",
  },
  { gene: "SLCO1B", variant: "*1, *5, *6, *7, *10, *14, *15, *43" },
  {
    gene: "CYP2C19",
    variant: "*1, *2, *3, *4, *5, *6, *7, *8, *9, *10, *12, *13, *15, *17, *35",
  },
];

const test_detail_summary = [
  {
    gene: "CYP2D6",
    variant: "*1/*1",
    phenotype: "Normal Metabolizer",
    overview: "Standard dosing recommended for drugs metabolized by CYP2D6.",
  },
  {
    gene: "CYP2C19",
    variant: "*2/*2",
    phenotype: "Poor Metabolizer",
    overview:
      "Caution: Increased risk of adverse effects with drugs metabolized by CYP2C19.",
  },
  {
    gene: "VKORC1",
    variant: "AA",
    phenotype: "Normal Sensitivity",
    overview: "Standard dosing for drugs like warfarin is appropriate.",
  },
  {
    gene: "MTHFR",
    variant: "TT",
    phenotype: "Normal",
    overview: "No increased risk for methotrexate toxicity.",
  },
  {
    gene: "CYP2D6",
    variant: "*1/*1",
    phenotype: "Normal Metabolizer",
    overview: "Standard dosing recommended for drugs metabolized by CYP2D6.",
  },
  {
    gene: "CYP2C19",
    variant: "*2/*2",
    phenotype: "Poor Metabolizer",
    overview:
      "Caution: Increased risk of adverse effects with drugs metabolized by CYP2C19.",
  },
  {
    gene: "VKORC1",
    variant: "AA",
    phenotype: "Normal Sensitivity",
    overview: "Standard dosing for drugs like warfarin is appropriate.",
  },
  {
    gene: "MTHFR",
    variant: "TT",
    phenotype: "Normal",
    overview: "No increased risk for methotrexate toxicity.",
  },
];

const recommendations_example = [
  {
    drugs: "Amitriptyline",
    status: "Actionable",
    cpic: "CYP2D6 Normal Metabolizer",
    cpic_text:
      "Initiate therapy with recommended starting dose. Patients may receive an initial low dose of a tricyclic, which is then increased over several days to the recommended steady-state dose. The starting dose in this guideline refers to the recommended steady-state dose.",
    dpwg: "CYP2D6 Normal Metabolizer",
    dpwg_text:
      "No specific recommendations based on genotype have been developed.",
  },
  {
    drugs: "Amoxapine",
    status: "Actionable",
    cpic: "CYP2D6 Normal Metabolizer",
    cpic_text:
      "Initiate therapy with the recommended starting dose for normal metabolizers.",
    dpwg: "CYP2D6 Normal Metabolizer",
    dpwg_text:
      "No specific recommendations based on genotype have been developed.",
  },
  {
    drugs: "Amphetamine",
    status: "Informative",
    cpic: "CYP2D6 Normal Metabolizer",
    cpic_text: "Initiate therapy with recommended starting dose.",
    dpwg: "CYP2D6 Normal Metabolizer",
    dpwg_text:
      "No specific recommendations based on genotype have been developed.",
  },
  {
    drugs: "Aripiprazole",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Aripiprazole Lauroxil",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Brexpiprazole",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Bupropion",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Cariprazine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Citalopram",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Clozapine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Desipramine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Desvenlafaxine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Doxepin",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Duloxetine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Escitalopram",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Fluoxetine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Fluvoxamine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Iloperidone",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Imipramine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Modafinil",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Nefazodone",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Nortriptyline",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Paliperidone",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Paroxetine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Perphenazine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Pimozide",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Pitolisant",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Protriptyline",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Risperidone",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Thioridazine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Trimipramine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Venlafaxine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Viloxazine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
  {
    drugs: "Vortioxetine",
    status: "null",
    cpic: "null",
    cpic_text: "null",
    dpwg: "null",
    dpwg_text: "null",
  },
];

export const ResultDetails = ({ deviceStarted }) => {
  usePageTitle("Result Details")
  const { testId } = useParams(); // unique id for each test
  const navigate = useNavigate();

  const [test, setTest] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [currentTab, setCurrectTab] = useState(0);

  const [seeMore, setSeeMore] = useState(
    recommendations_example.map(() => false)
  );
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    selectedTest: null,
  });

  const shareResult = (test) => {
    setOpenModal({ isOpen: true, selectedTest: test });
  };

  const closeModal = () => {
    setOpenModal({ isOpen: false, selectedTest: null });
  };
  const handleSeeMore = (index) => {
    setSeeMore((prev) => prev.map((item, i) => (i === index ? !item : item)));
  };

  const fetchResult = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/test/${testId}`);
      setTest(response.data.test);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchResult();
  }, []);

  return (
    <div className="text-[20px] px-32 w-full pb-16">
      <div className="flex justify-between items-center mt-12 mb-3 relative">
        <button
          onClick={() => navigate("/results")}
          className="text-left flex space-x-1 items-start text-brand-dark-blue text-lg  font-semibold"
        >
          {" "}
          <Icon icon={chevronLeft} size={20} />{" "}
          <p className="mt[-19px] text-[20px]">Back to all results</p>
        </button>
        <div className="flex justify-center items-center space-x-10">
          <button
            onClick={() => shareResult(testId)}
            className="flex items-center space-x-2"
          >
            <img src={UploadIcon} alt="share-icon" />
            <span>Share</span>
          </button>
          <PDFDownloadLink
            document={<PDFFile />}
            fileName="ProbeiT_test_results.pdf"
          >
            <div className="flex items-center space-x-2">
              <img src={DownloadIcon} alt="download-icon" />
              <span>Download</span>
            </div>
          </PDFDownloadLink>
        </div>
        {deviceStarted && <Toast />}
      </div>
      <div className="pt-2 pb-12 text-lg">
        <div className="flex flex-row border-y border-y-brand-dark-blue pt-4 justify-evenly items-center">
          {tabs.map((tab, i) => (
            <div
              key={i}
              className={`${
                i === currentTab
                  ? "font-semibold border-b-brand-dark-blue border-b-2"
                  : ""
              } pb-4 text-brand-dark-blue cursor-pointer text-lg`}
              onClick={() => {
                setCurrectTab(i);
              }}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>

      <div className="">
        {currentTab === 0 && (
          <div className="flex items-center">
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="grid grid-cols-2 gap-52 text-left text-[#1a1a1a] px-24">
                <div className="flex flex-col space-y-5">
                  <p className="font-bold">
                    Full Name:{" "}
                    <span className="font-medium">{test?.fullName}</span>
                  </p>
                  <p className="font-bold">
                    Requesting Healthcare Provider:{" "}
                    <span className="font-medium">
                      {test?.requestingHealthCareProvider}
                    </span>
                  </p>
                  <p className="font-bold">
                    Date of Birth:{" "}
                    <span className="font-medium">
                      {test?.dateOfBirth && formatDateMMDDYYYY(test.dateOfBirth)}
                    </span>
                  </p>
                  <p className="font-bold">
                    Health Card No:{" "}
                    <span className="font-medium">
                      {test?.healthCardNumber}
                    </span>
                  </p>
                  <p className="font-bold">
                    Requisition No.:{" "}
                    <span className="font-medium">
                      {test?.requisitionNumber}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col space-y-5">
                  <p className="font-bold">
                    Phone Number:{" "}
                    <span className="font-medium">{test?.phoneNumber}</span>
                  </p>
                  <p className="font-bold">
                    Address:{" "}
                    <span className="font-medium">{test?.address}</span>
                  </p>
                  <p className="font-bold">
                    Ordering Clinician:{" "}
                    <span className="font-medium">
                      {test?.nameOfOrderingClinician}
                    </span>
                  </p>
                  <p className="font-bold">
                    Health Plan Beneficiary No.:{" "}
                    <span className="font-medium">
                      {test?.healthPlanBeneficiaryNumber}
                    </span>
                  </p>
                  <p className="font-bold">
                    Medical Record No.:{" "}
                    <span className="font-medium">
                      {test?.medicalRecordNumber}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}

        {currentTab === 1 && (
          <div className="flex items-center">
            <div className="grid grid-cols-2 gap-52 text-left text-[#1a1a1a] px-24">
              <div className="flex flex-col space-y-5">
                <p className="font-bold">
                  Test ID: <span className="font-medium">{test?.testId}</span>
                </p>
                <p className="font-bold">
                  Referring Institution:{" "}
                  <span className="font-medium">
                    {test?.referringInstitution}
                  </span>
                </p>
                <p className="font-bold">
                  Type of Test:{" "}
                  <span className="font-medium">{test?.typeOfTest}</span>
                </p>
                <p className="font-bold">
                  Copy to:{" "}
                  <span className="font-medium">{test?.providerEmail}</span>
                </p>
              </div>
              <div className="flex flex-col space-y-5">
                {/* <p className="font-bold">
                Date Requested: <span className="font-medium">{test?.dateAdministered}</span>
                </p> */}
                <p className="font-bold">
                  Sample Collected:{" "}
                  <span className="font-medium">
                    {test?.timeOfSampleCollection && formatDateMMDDYYYYHHMMA(test?.timeOfSampleCollection)}
                  </span>
                </p>
                <p className="font-bold">
                  Test Initiated:{" "}
                  <span className="font-medium">
                    {test?.timeReceived && formatDateMMDDYYYYHHMMA(test?.timeReceived)}
                  </span>
                </p>
                <p className="font-bold">
                  Time Reported:{" "}
                  <span className="font-medium"> 
                    {/* This value will be sent from the rasberry device. */}
                    {test?.timeReceived && formatDateMMDDYYYYHHMMA(test?.timeReceived)}  
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}

        {currentTab === 2 && (
          <table className="border-collapse w-full">
            <thead className="bg-brand-dark-blue text-white text-center text-[24px]">
              <tr>
                <th className="p-4 border-r-0">Gene</th>
                <th className="p-4 border-r-0 w-1/2">Genetic Variant</th>
              </tr>
            </thead>
            <tbody className="text-center text-brand-dark-blue">
              {gene_example_data.map((item) => {
                return (
                  <tr className="border-b border-brand-dark-blue cursor-pointer hover:bg-slate-100 align-top">
                    <td className="p-4">{item.gene}</td>
                    <td className="p-4">{item.variant}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {currentTab === 3 && (
          <table className="border-collapse w-full">
            <thead className="bg-brand-dark-blue text-white text-center text-[24px]">
              <tr>
                <th className="p-4 border-r-0">Gene</th>
                <th className="p-4 border-r-0">Variant</th>
                <th className="p-4 border-r-0">Phenotype</th>
                <th className="p-4 border-r-0 w-[45%]">Overview</th>
              </tr>
            </thead>
            <tbody className="text-center text-brand-dark-blue">
              {test_detail_summary.map((item) => {
                return (
                  <tr className="border-b border-brand-dark-blue cursor-pointer align-top">
                    <td className="p-4">{item.gene}</td>
                    <td className="p-4">{item.variant}</td>
                    <td className="p-4">{item.phenotype}</td>
                    <td className="p-4 text-left">{item.overview}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {currentTab === 4 && (
          <table className="border-collapse w-full">
            <thead className="bg-brand-dark-blue text-white text-center text-[24px]">
              <tr>
                <th className="p-4 border-r-0 w-[15%]">Drugs</th>
                <th className="p-4 border-r-0 w-[15%]">Status</th>
                <th className="p-4 border-r-0 w-[30%]">CPIC</th>
                <th className="p-4 border-r-0 w-[40%]">DPWG</th>
              </tr>
            </thead>
            <tbody className="text-center text-brand-dark-blue">
              {recommendations_example.map((item, index) => {
                return (
                  <>
                    <tr
                      className={`${
                        !seeMore[index] ? "border-b border-brand-dark-blue" : ""
                      } cursor-pointer align-top !font-medium border-brand-dark-blue`}
                    >
                      <td className="px-4 pt-6 pb-8 border-b-0">
                        {item.drugs}
                      </td>
                      <td className="px-4 pt-6 pb-8 text-red-600 font-semibold border-b-0">
                        {item.status}
                      </td>
                      <td className="px-4 pt-6 pb-8 border-b-0">{item.cpic}</td>
                      <td className="px-4 pt-6 pb-8 text-left flex flex-col space-y-1 relative border-b-0">
                        <span>{item.dpwg}</span>
                        <span className="font-light !mb-4">
                          {item.dpwg_text}
                        </span>
                        <button
                          onClick={() => handleSeeMore(index)}
                          className="text-left flex absolute space-x-1 right-6 bottom-0 items-start text-brand-dark-blue !mb-6 ml-6"
                        >
                          <span className="underline">See more</span>
                          <Icon
                            icon={seeMore[index] ? chevronUp : chevronDown}
                            size={20}
                          />
                        </button>
                      </td>
                    </tr>
                    {seeMore[index] && (
                      <tr className="border-b border-brand-dark-blue">
                        <td colSpan="4" className="p-0 mb-10">
                          <p className="bg-brand-dark-blue text-white mb-5 p-4 border-t-0 shadow-2xl text-left">
                            Initiate therapy with recommended starting dose.
                            Patients may receive an initial low dose of a
                            tricyclic, which is then increased over several days
                            to the recommended steady-state dose. The starting
                            dose in this guideline refers to the recommended
                            steady-state dose.
                          </p>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <ShareResultModal
        open={openModal.isOpen}
        onClose={closeModal}
        copyTo={test?.providerEmail}
      />
    </div>
  );
};

export default ResultDetails;
