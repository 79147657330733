import { useEffect, useState } from "react";
import searchIcon from "../resources/search.png";

const Search = ({ setSearchQuery }) => {
  const [search, setSearch] = useState("");

  const handleSearchChange = (event) => {
    if (!event.target.value) {
      setSearch("");
    } else {
      setSearch(event.target.value);
    }
  };

  useEffect(() => {
    setSearchQuery(search);
  }, [search]);

  return (
    <>
      <input
        type="text"
        className="border-b border-brand-dark-blue w-full focus:outline-none pt-4"
        value={search}
        onChange={handleSearchChange}
        placeholder="Search"
      />
      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
        <img
          src={searchIcon}
          alt="search icon"
          className="object-cover"
        />
      </span>
    </>
  );
};

export default Search;
