import "./Login.css";
import axios from "../../api/axios";
import { useUser } from "./UserContext";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { OTPForm } from "../common/OTPForm";
import { useForm } from "react-hook-form";
import usePageTitle from "../../hooks/usePageTitle";

const TwoFactorAuth = () => {
  usePageTitle('Two Factor Authentication')
  const { userData, updateUserData } = useUser();
  const navigate = useNavigate();
  let inputRefs = useRef([]);

  const [input, setInput] = useState({
    field_1: "",
    field_2: "",
    field_3: "",
    field_4: "",
  });
  const { reset } = useForm({
    defaultValues: { ...input },
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const clearInputs = () => {
    reset({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
    setInput({
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
    });
  };

  const verifyOTP = async () => {
    setErrorMessage("");
    setIsLoading(true);
    if (Object.values(input).some((value) => value === "")) {
      setErrorMessage("Invalid OTP");
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post("/verify-otp", {
        email: userData.email,
        otp: input.field_1 + input.field_2 + input.field_3 + input.field_4,
      });
      localStorage.setItem("token", JSON.stringify(response.data.token)); // Store the token
      updateUserData({username: response.data.user.username, userid: response.data.user.userid, isLoggedin: true})
      navigate("/"); // Navigate to the home page
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      clearInputs();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <nav className="navbar border-t border-t-brand-dark-blue text-brand-dark-blue w-full">
        <div className="flex justify-between items-center w-full">
          <button
            className={`nav-button w-full ease-out transition-all text-2xl border-b border-b-brand-dark-blue font-bold py-4 mb-6`}
          >
            Login
          </button>
        </div>
      </nav>
      <OTPForm
        onSubmit={verifyOTP}
        isLoading={isLoading}
        input={input}
        inputRefs={inputRefs}
        setInput={setInput}
        btnText="Login"
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export default TwoFactorAuth;
