import React from "react";
import {Link, NavLink} from "react-router-dom";
import {useState} from 'react';
import logo from '../resources/ProbeIT.png'
import cloud from '../resources/download.png'
import Navbar from '../Navbar/Navbar.jsx'
import googledrive from '../resources/googledrive.png'
import onedrive from '../resources/onedrive.png'
import dropbox from '../resources/dropbox.png'
import {Share, Cloud} from './Cloud.jsx'
import './Results.css';


export const History = () => {
    const history_example = [
        {patient: "Mary Sue", name: "Oral Glucose", loc: "St. Jude Lab", doctor: "Dr Penny Klev", date_issued: "15/07/2023"},
        {patient: "Mary Sue", name: "Sugar Level", loc: "St. Jude Lab", doctor: "Dr Priya Rajesh", date_issued: "17/07/2023"},
        {patient: "Mary Sue", name: "Haemoglobine", loc: "St. Jude Lab", doctor: "Dr Penny Klev", date_issued: "19/07/2023"},
        {patient: "Mary Sue", name: "A1C Test", loc: "St. Jude Lab", doctor: "Dr Penny Klev", date_issued: "21/07/2023"}
    ];
    const [showShare, setShowShare] = useState(-1);
    return (
        <div className= "w-full min-h-screen flex flex-col">
            <div className="w-full flex flex-row">
                <div className="w-1/6">
                    <img src={logo} className="w-full object-cover"/>
                </div>
                <div className='w-5/6 pt-8 px-8'>
                    <div className="sndhalf"><Navbar/></div> 

                    <div className="flex flex-col midwidth py-20">
                        <h1 className="histtitle pt-28 pb-6 text-left">Test Results History</h1>
                        <div className="flex flex-row">
                            <div className="tableheaderhist w-1/6">Patient Name</div>
                            <div className="tableheaderhist w-1/6">Test Type</div>
                            <div className="tableheaderhist w-1/6">Lab/Hospital</div>
                            <div className="tableheaderhist w-1/6">Doctor</div>
                            <div className="tableheaderhist w-1/6">Date Issued</div>
                            <div className="tableheaderhist w-1/6">Share</div>
                        </div>
                        {history_example.map((row, i) => {
                            return (
                            <div className="flex flex-row">
                                <div className="tablerowhist w-1/6">{row.patient}</div>
                                <div className="tablerowhist w-1/6">{row.name}</div>
                                <div className="tablerowhist w-1/6">{row.loc}</div>
                                <div className="tablerowhist w-1/6">{row.doctor}</div>
                                <div className="tablerowhist w-1/6">{row.date_issued}</div>
                                <div className="tablerowhist w-1/6">
                                    <button onClick={()=>setShowShare(i)} className={`download flex flex-row items-center px-2 pl-2 ${showShare === i && "downloadactive"}`}>Share <span className="pl-2"><img src={cloud}/></span></button>
                                </div>
                            </div>
                        );})
                        }                 
                    </div> 
                             
                </div>
            </div>
            <div className="flex flex-col items-around ml-16 pb-12">
                        {showShare > -1 && 
                        <div className=""> 
                            <Share/>
                        </div>}
                    </div>    
            
            <div className="py-8 flex flex-row justify-start">
                <Link to="/results"><button className="resultbtn2 ml-6">Back to result page</button></Link>
            </div>
        </div>

    );
}

export default History